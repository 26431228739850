<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" v-if="profile == 3">
        <v-card>
          <v-card-title><h4>Ajout d'une visite de site</h4></v-card-title>
          <v-card-text>
            <date-picker
              label="Date/heure de la visite de site"
              v-model="visit.date"
              value-type="format"
              format="YYYY-MM-DD H:m:s"
              type="datetime"
              :default-value="new Date()"
              class="mb-3"
              placeholder="Date/heure de la visite de site"
            ></date-picker>
            <v-autocomplete
              v-if="this.$store.getters['Auth/getListDoc']"
              v-model="visit.document"
              :items="this.$store.getters['Auth/getListDoc']"
              item-text="object"
              item-value="id"
              outlined
              label="Choisir le dossier concerné par la visite de site"
            ></v-autocomplete>
            <v-btn color="primary" @click="addVisit" class="ma-2 white--text" block>
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Valider la programmation de la visite de site
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :md="dynamicCol">
        <v-card>
          <v-card-title>
            <h4>Liste détaillée des visites de sites programmées</h4>
            <v-spacer></v-spacer
            ><v-chip class="ma-2">{{ this.$store.getters["Auth/getVisitList"].length }}</v-chip>
          </v-card-title>
          <v-divider></v-divider>
          <v-virtual-scroll
            :items="this.$store.getters['Auth/getVisitList']"
            :item-height="50"
            height="400"
          >
            <template v-slot="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon large>mdi-account-supervisor-circle</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.document.object }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.visitDate | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click="seevisit(item)" depressed small>
                    Consulter
                    <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    visit: {
      date: null,
      document: ""
    },
    dynamicCol: 6
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    if (this.profile == 2) {
      this.dynamicCol = 12;
    }
    this.$store.dispatch("Auth/loadAllNotice");
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/loadAllVisit");
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    addVisit() {
      const data = new FormData();
      data.append("date", this.visit.date);
      data.append("document", this.visit.document);
      this.$store.dispatch("Auth/addVisit", data);

      this.visit.date = null;
      this.visit.document = "";
    },
    seevisit(item) {
      console.log(item);
      this.$router.push({ name: "showVisit", params: { id: item.id } });
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
