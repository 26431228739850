<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <h4>
              Visite de site du dossier concerné
              <!--{{ this.$store.getters["Auth/get_selected_visit"].document.documentType.description }}-->
            </h4>
            <v-spacer></v-spacer>
            <v-btn
              v-if="profile == 2"
              color="primary"
              class="white--text"
              @click="addVisitPresence"
            >
              <v-icon class="mr-2" dark>mdi-circle</v-icon><small>CONFIRMER MA PARTICIPATION</small>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card color="primary" class="mb-3">
              <v-card-title class="white--text">
                <v-icon color="white">mdi-account-supervisor-circle</v-icon> <v-spacer></v-spacer>
                {{ this.$store.getters["Auth/get_selected_visit"].document.object
                }}<v-spacer></v-spacer>
                <h4>
                  {{
                    this.$store.getters["Auth/get_selected_visit"].visitDate
                      | moment("dddd, Do MMMM YYYY")
                  }}
                </h4>
                <v-spacer></v-spacer>
                <h4>{{ getRemaining }}</h4>
              </v-card-title>
            </v-card>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
        <v-card
          class="mt-3"
          v-if="profile != 2 && profile != 4 && !this.$store.getters['Auth/get_selected_visit_pv']"
        >
          <v-card-title>
            Publication du Procès-Verbal (PV) de la visite de site <v-spacer></v-spacer>
            <v-btn color="primary" class="white--text" @click="addPv">
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>Publier le PV de la visite de site
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-file-input v-model="pv" multiple label="Joindre le PV de la visite de site" truncate-length="15">
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
        </v-card>
        <v-card class="mt-3" v-if="this.$store.getters['Auth/get_selected_visit_pv']">
          <v-card-text>
            <v-list-item class="block text-truncate">
              <v-list-item-content>
                <v-list-item-title class="primary--text text-truncate font-weight-bold">
                  Consultation Procès-verbal
                  <v-divider></v-divider>
                </v-list-item-title>
                <v-list-item-subtitle class="text-truncate">Date/Heure de la publication du Procès-Verbal de la visite de site {{
                  this.$store.getters["Auth/get_selected_visit_pv"].createdAt
                    | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-star</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
        <v-card v-if="profile != 2" class="mt-3">
          <v-card-title>
            Liste des candidats invités pour la conférence préalable (cabinets/bureaux  d’étude/entreprises/fournisseurs/prestataires) pour confirmation  <v-spacer></v-spacer>
            <v-chip>{{ this.$store.getters["Auth/getListVisitPresence"].length }}</v-chip>
          </v-card-title>
          <v-card-text>
            <v-list-item-group color="primary">
              <v-list-item
                three-line
                v-for="item in this.$store.getters['Auth/getListVisitPresence']"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.user.firstname"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.user.email"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="this.$store.getters['Auth/get_selected_visit_pv']">
        <v-card style="height:62em">
          <v-card-title>
            <h4>Aperçu du Procès-Verbal de la visite de site</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <VueDocPreview
                v-if="
                  this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'docx' ||
                    this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'doc' ||
                    this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'xls' ||
                    this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'xlsx' ||
                    this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'XLS' ||
                    this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'XLSX'
                "
                style="height:62em;width:100%"
                frameborder="0"
                :value="this.$store.getters['Auth/get_selected_visit_pv'].fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'pdf'"
                :src="this.$store.getters['Auth/get_selected_visit_pv'].fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import "vue2-datepicker/index.css";
import moment from "moment";
import VueDocPreview from "vue-doc-preview";

export default {
  components: { VueDocPreview },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    extracted_visit: [],
    show: false,
    pv: [],
    ppm: [],
    additif: false,
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Documents",
        disabled: false,
        href: "/notice"
      },
      {
        text: "Détails du document",
        disabled: true
      }
    ]
  }),
  computed: {
    getRemaining() {
      return moment(this.$store.getters["Auth/get_selected_visit"].visitDate).from(new Date());
    }
  },
  created() {
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/getVisit", this.id);
    this.$store.dispatch("Auth/getVisitPv", this.id);
    this.$store.dispatch("Auth/loadListPresence", this.id);
    this.$store.dispatch("Auth/loadAllVisit");
    this.$store.dispatch("Auth/loadAllPv");
  },
  methods: {
    addPv() {
      const data = new FormData();
      data.append("file", this.pv[0]);
      data.append("visit", this.id);
      this.$store.dispatch("Auth/addPV", data);
    },
    addVisitPresence() {
      const data = new FormData();
      data.append("visit_id", this.id);
      this.$store.dispatch("Auth/addVisitPresence", data);
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
