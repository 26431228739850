var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(this.id),expression:"this.id"}]},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v(" Visite de site du dossier concerné ")]),_c('v-spacer'),(_vm.profile == 2)?_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.addVisitPresence}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-circle")]),_c('small',[_vm._v("CONFIRMER MA PARTICIPATION")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-card',{staticClass:"mb-3",attrs:{"color":"primary"}},[_c('v-card-title',{staticClass:"white--text"},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account-supervisor-circle")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "+_vm._s(this.$store.getters["Auth/get_selected_visit"].document.object)),_c('v-spacer'),_c('h4',[_vm._v(" "+_vm._s(_vm._f("moment")(this.$store.getters["Auth/get_selected_visit"].visitDate,"dddd, Do MMMM YYYY"))+" ")]),_c('v-spacer'),_c('h4',[_vm._v(_vm._s(_vm.getRemaining))])],1)],1),_c('v-divider')],1)],1),(_vm.profile != 2 && _vm.profile != 4 && !this.$store.getters['Auth/get_selected_visit_pv'])?_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_vm._v(" Publication du Procès-Verbal (PV) de la visite de site "),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.addPv}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-cloud-upload")]),_vm._v("Publier le PV de la visite de site ")],1)],1),_c('v-card-text',[_c('v-file-input',{attrs:{"multiple":"","label":"Joindre le PV de la visite de site","truncate-length":"15"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(_vm._s(text))])]}}],null,false,3534825248),model:{value:(_vm.pv),callback:function ($$v) {_vm.pv=$$v},expression:"pv"}})],1)],1):_vm._e(),(this.$store.getters['Auth/get_selected_visit_pv'])?_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_c('v-list-item',{staticClass:"block text-truncate"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text text-truncate font-weight-bold"},[_vm._v(" Consultation Procès-verbal "),_c('v-divider')],1),_c('v-list-item-subtitle',{staticClass:"text-truncate"},[_vm._v("Date/Heure de la publication du Procès-Verbal de la visite de site "+_vm._s(_vm._f("moment")(this.$store.getters["Auth/get_selected_visit_pv"].createdAt,"dddd, Do MMMM YYYY à HH:mm:ss")))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-star")])],1)],1)],1)],1)],1):_vm._e(),(_vm.profile != 2)?_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_vm._v(" Liste des candidats invités pour la conférence préalable (cabinets/bureaux d’étude/entreprises/fournisseurs/prestataires) pour confirmation "),_c('v-spacer'),_c('v-chip',[_vm._v(_vm._s(this.$store.getters["Auth/getListVisitPresence"].length))])],1),_c('v-card-text',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((this.$store.getters['Auth/getListVisitPresence']),function(item){return _c('v-list-item',{key:item.id,attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.user.firstname)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.user.email)}})],1)],1)}),1)],1)],1):_vm._e()],1),(this.$store.getters['Auth/get_selected_visit_pv'])?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticStyle:{"height":"62em"}},[_c('v-card-title',[_c('h4',[_vm._v("Aperçu du Procès-Verbal de la visite de site")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',[(
                this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'docx' ||
                  this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'doc' ||
                  this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'xls' ||
                  this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'xlsx' ||
                  this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'XLS' ||
                  this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'XLSX'
              )?_c('VueDocPreview',{staticStyle:{"height":"62em","width":"100%"},attrs:{"frameborder":"0","value":this.$store.getters['Auth/get_selected_visit_pv'].fileLink,"type":"office"}}):_vm._e(),(this.$store.getters['Auth/get_selected_visit_pv'].fileType === 'pdf')?_c('vue-friendly-iframe',{staticClass:"w-62em",attrs:{"src":this.$store.getters['Auth/get_selected_visit_pv'].fileLink,"frameborder":"0","loading":"lazy"}}):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }